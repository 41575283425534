.App {
  text-align: center;
}

.App-logo {
  height: 100px;
  width: 100px;
  pointer-events: none;
}

.image-div {
  height: 100px;
  width: 100px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* CSS for centering the button and content vertically and horizontally */
.center-container {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CSS for styling the button */
.color-change-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}